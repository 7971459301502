



































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class EmailTemplateDeleteDialogComponent extends Vue {
  @PropSync('show', { default: false }) modelSync!: boolean;

  confirm() {
    this.modelSync = false;
    this.$emit("click:confirm");
  }
}
